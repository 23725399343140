import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import DavidCEO from '../../images/directors1.jpg'
import BG from '../../images/awards-bg.jpg'

class DavidShawCEO extends React.Component {
	render() {
		const siteTitle = 'David Shaw'
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div class="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">David Shaw (CEO)</h3>
								<div className="row">
									<div className="col-12">
										<p>
											David Shaw is the CEO of WSC Group. Under David’s
											directorship, WSC Group is focused on delivering
											remarkable value to clients across Australia. With over 25
											years of business experience, David has been instrumental
											in developing a team culture built on excellence.
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<p>
											David has many years of accounting experience with
											extensive knowledge in commerce, industry and public
											practice accounting. During his accounting career, he has
											held senior accounting roles with Deloittes, Pacific
											Dunlop, Boral and National Foods. Prior to starting the
											WSC Group, over 10 years ago, David was a Director and CEO
											of the Pickles Auctions Group. Specialising in
											establishing financial systems and management controls,
											David offers his clients strategic planning and growth
											assistance.
										</p>
									</div>
									<div className="col-lg-6 col-md-12">
										<img
											src={DavidCEO}
											className="img-fluid"
											alt="David Shaw CEO"
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<p>
											David has also developed an extensive property expertise
											from the property investor level to the corporate property
											development of both new developments and second-hand
											property refurbishments. David has a degree in Business
											(Accounting Major with the University of Technology
											Sydney), is a CPA with a Public Practice Certificate and a
											Registered Tax Agent.
										</p>
										<p>
											David will assist his clients, whether it’s at board
											level, sole trader or individual investor level with their
											business, wealth building strategy, superannuation,
											taxation and accounting needs.
										</p>
										<h4>
											Your Investment Property Magazine Readers voted David as:
										</h4>
										<ul>
											<li>2013 Property Tax Specialist of the Year</li>
											<li>
												2015, 2014 and 2012 Runner-up Property Tax Specialist of
												the Year
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default DavidShawCEO

export const DavidShawCEOPageQuery = graphql`
	query DavidShawCEOPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
